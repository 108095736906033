<template>
  <div class="container">
    <!-- eslint-disable-next-line max-len -->
    <v-alert v-model="notification.show" type="error" transition="scale-transition" dismissible>
      {{ notification.message }}
    </v-alert>

    <!-- <Form @submit="login">
      <v-card class="elevation-12">
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title>Iniciar sesión</v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          eslint-disable-next-line max-len
          <TextField
            v-model="form.email"
            label="Correo electrónico"
            prepend-icon="mdi-account"
            rules="required"
          />
          eslint-disable max-len
          <TextField
            v-model="form.password"
            label="Contraseña"
            prepend-icon="mdi-lock"
            rules="required"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
          />
          eslint-enable max-len
        </v-card-text>

        <v-card-actions class="d-flex flex-column justify-center">
          <v-btn class="px-10 mb-3" color="primary" type="submit">
            <v-icon left>mdi-login</v-icon>
            Entrar
          </v-btn>

           <router-link :to="{ name: 'signup' }" class="my-3">
            No tengo cuenta
          </router-link>
        </v-card-actions>
      </v-card>
    </Form> -->
    <Form @submit="loginFormOnSend">
      <div class="row mt-5">
        <div class="col-12">
          <div class="card card-login">
            <div class="card-header bg-blue">
              <h5 class="color-white m-2">Iniciar sesión</h5>
            </div>
            <div class="card-body">
              <div class="input-group input-group-sm mb-3 mt-5">
                <v-icon left>mdi-account</v-icon>
                <input
                  type="email"
                  class="form-control"
                  v-model="form.email"
                  placeholder="Correo electrónico"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  required
                />
              </div>
              <div class="input-group input-group-sm mb-3 mt-5">
                <v-icon left>mdi-lock</v-icon>
                <input
                  class="form-control"
                  id="form3Example4"
                  v-model="form.password"
                  placeholder="Contraseña"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm"
                  :type="showPassword ? 'text' : 'password'"
                  required
                />
                <span class="input-group-text" @click="showPassword = !showPassword">
                  <i v-if="showPassword == true">
                    <v-icon>mdi-eye</v-icon>
                  </i>
                  <i v-else>
                    <v-icon>mdi-eye-off</v-icon>
                  </i>
                </span>
              </div>
              <div class="row mt-5">
                <div class="col-12 d-flex justify-content-center align-items-center">
                  <button type="submit" class="btn btn-primary btn-block mb-4 color-white">
                    <v-icon left class="color-white">mdi-login</v-icon>Entrar
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-12 d-flex justify-content-center">
                  <router-link :to="{ name: 'password-recovery-email' }">
                    Olvidé mi contraseña
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  </div>
</template>

<script>
// API
import AuthAPI from '@/api/auth';
import { mapActions } from 'vuex';

export default {
  name: 'FrontendLogin',

  data: () => ({
    form: {
      email: null,
      password: null,
    },
    notification: {
      show: false,
      message: null,
    },
    showPassword: false,
  }),

  created() {
    this.api = new AuthAPI();
    if (this.getProfileFromStorage()) {
      this.$store.commit('showLoader');
      this.api
        .profile()
        .then((profile) => {
          console.log('profile');
          console.log(profile);
        })
        .catch((error) => {
          console.warn('error');
          console.warn(error);
        })
        .finally(() => {
          this.$store.commit('hideLoader');
        });
    }
  },

  methods: {
    ...mapActions('signup', ['getProfileFromStorage', 'getCartFromStorage']),
    loginFormOnSend() {
      return this.login(this.form);
    },
    async login(formData) {
      this.$store.commit('showLoader');
      try {
        const tokens = await this.api.login(formData);
        this.$store.dispatch('auth/setTokens', tokens);
        const profile = await this.api.profile();
        this.$store.commit('auth/setProfile', profile);
        const currentCart = await this.getCartFromStorage();
        if (currentCart.length > 0) {
          if (this.$router.currentRoute.name === 'cart') {
            this.$router.go(0);
          } else {
            this.$router.push('cart');
          }
        } else {
          this.$router.push('/');
        }
      } catch (error) {
        switch (error.status) {
          case 400:
          case 401:
            this.notification.message = 'Credenciales incorrectas.';
            break;

          default:
            this.notification.message = 'Ocurrió un error. Por favor, vuelve a intentar.';
        }
        this.notification.show = true;
      } finally {
        this.$store.commit('hideLoader');
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-blue {
  background-color: #1976d2 !important;
}
.color-white {
  color: #fff !important;
  font-size: 20px;
}
.text-btn-login {
  font-size: 10px;
}
.card-login {
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6);
}
</style>
